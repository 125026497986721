import { logError } from '@core/logging'
import { safeLogVariable } from '@core/logging/helpers'
import type { EnumUtmKeys } from '@core/tracking/funnelTracking'
import {
  EnumFunnelTrackingCookies,
  getCookieWithFallback,
} from '@core/tracking/funnelTracking'

/**
 * Read UTM from cookies/localStorage and returns object which is appended
 * to every tracking pixel
 */
export const getUTM = (): Record<EnumUtmKeys, string> | undefined => {
  let utms
  try {
    utms = getCookieWithFallback(EnumFunnelTrackingCookies.FW_UTM)?.value
    return utms ? JSON.parse(utms) : undefined
  } catch (e) {
    logError(e, {
      captureContext: {
        extra: {
          utms: safeLogVariable(utms),
        },
      },
    })
  }
}
